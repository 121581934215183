@font-face {
	font-family:'Adelle Sans EXT Light';
    src: url('../fonts/AdelleSansEXT-Light.ttf') format("truetype");
	font-weight: 300;
	font-style: normal;
}
$adelle: "Adelle Sans EXT Light" , sans-serif;
$arima: 'Arima Madurai', cursive;
$fa-font-path:        "./../fonts";
$primary: #151945;
$secondary:#cb6b1d;
$gris:#f6f6f6;
$white: #FFF;