@import "utils";
.header{
    position: relative;
    z-index: 10;
    .navbar{
        padding: 0;
        align-items: flex-start;
        position: fixed;
        top: 0;
        width: 100%;
        @media (max-width: 767px){
            align-items: center;
            background-color: $primary;
        }
        .navbar-toggler .icon-bar {
            width: 22px;
            -webkit-transition: all .2s;
            -o-transition: all .2s;
            transition: all .2s;
            display: block;
            height: 2px;
            border-radius: 1px;
            background-color: $white;
        }
        .navbar-toggler{
            margin-left: auto;
            .icon-bar+.icon-bar, .icon-bar+.icon-bar+.icon-bar {
                margin-top: 4px;
            }
            .icon-bar.icon-bar:nth-of-type(2) {
                opacity: 0;
                filter: alpha(opacity=0);
            }
            &.collapsed{
                .icon-bar.icon-bar:nth-of-type(2) {
                    opacity: 1;
                }
                .icon-bar:first-of-type {
                    transform: rotate(0);
                }
                .icon-bar:nth-of-type(3) {
                    transform: rotate(0);
                }
            }
            .icon-bar:first-of-type {
                transform: rotate(45deg);
                transform-origin: 10% 10%;
            }
            .icon-bar:nth-of-type(3) {
                transform: rotate(-45deg);
                transform-origin: 10% 90%;
            }
        }
        .navbar-brand{
            width: 250px;
            background-color: $white;
            text-align: center;
            position: relative;
            @media (max-width: 767px){
                width: 180px;
            }
            &:before{
                content: '';
                background-color: $white;
                top: 0;
                bottom: 0;
                right: -7%;
                position: absolute;
                transform: skew(-10deg);
                z-index: 0;
                left: 90%;
            }
            img{
                max-width: 125px;
                position: relative;
                transition: 0.4s;
                @media (max-width: 767px){
                    max-width: 100px;
                }
            }
        }
        .navbar-collapse{
            background-color: $primary;
            padding: 16px;
            transition: .4s;
            .navbar-nav{
                align-items: center;
                @media (max-width: 767px){
                    align-items: flex-start;
                }
                .nav-item{
                    margin-right: 16px;
                    &.active{
                        .nav-link{
                            color:$secondary;
                            font-weight: 700;
                            border-bottom: 2px solid $secondary;
                        }
                    }
                    .nav-link{
                        color: $white;
                        text-transform: uppercase;
                        font-size: 15px;
                    }
                }
            }
        }
    }
}
.section{
    position: relative;
    padding: 54px 0;
    &.slider-home{
        margin-top: 72px;
        padding: 0;
        margin-bottom: 0;
        @media (max-width: 767px){
            margin-top: 125px;
        }
        .slide{
            min-height: calc(100vh - 72px);
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .slick-dots{
            bottom: 0;
            li{
                width: auto;
                height: auto;
                margin: 0;
                button{
                    &:before{
                        content:"";
                        background-color:$secondary;
                        opacity: 1;
                        width: 5px;
                        height: 8px;
                    }
                }
                &.slick-active{
                    button{
                        &:before{
                            background-color:$white;
                        }
                    }
                }
            }
        }
        .slick-arrow{
            z-index: 1;
            background-color: $secondary;
            padding: 1rem 22px;
            height: auto;
            width: auto;
            @media (max-width: 767px){
                padding: 8px 12px;
            }
            &.slick-next{
                right: 0;
                &:before{
                    content: '';
                    background-color: $secondary;
                    top: 0;
                    bottom: 0;
                    right: 90%;
                    position: absolute;
                    transform: skew(170deg);
                    z-index: 0;
                    left: -7%;
                    opacity: 1;
                }
            }
            &.slick-prev{
                left: 0;
                &:before{
                    content: '';
                    background-color: $secondary;
                    top: 0;
                    bottom: 0;
                    right: -7%;
                    position: absolute;
                    transform: skew(-10deg);
                    z-index: 0;
                    left: 90%;
                    opacity: 1;
                }
            }
            .fas{
                color: $white;
                font-size: 20px;
            }
        }
        .title{
            @media (max-width: 767px){
                margin:0 36px;
            }
            .ttr,.para{
                color: $white;
                margin-bottom: 0;
                text-shadow: 1px 1px 5px rgba($color: #000000, $alpha: 0.5);
            }
            .vs{
                margin: 0 32px;
            }
        }
    }
    &.news{
        padding: 0;
        .news-card{
            display: flex;
            max-width: 700px;
            height: 300px;
            position: relative;
            /*&:before, &:after{
                content:"";
                width: 0;
                height: 2px;
                position: absolute;
                transition: all 0.2s linear;
                background: $primary;
                z-index: 1;
            }
            &:before{
                right: 0;
                top: 0;
            }
            &:after{
                left: 0;
                bottom: 0;
            }
            &:hover{
                &:before, &:after{
                    width: 100%;
                }
            }*/
            &:nth-child(odd){
                .info{
                    background-color: $secondary;
                    &:before{
                        background-color: $secondary;
                    }
                }
            }
            &:nth-child(even){
                .info{
                    background-color: $primary;
                    &:before{
                        background-color: $primary;
                    }
                }
            }
            .info{
                width: 350px;
                padding: 16px;
                position: relative;
                &:before{
                    content: '';
                    top: 0;
                    bottom: 0;
                    right: -11%;
                    position: absolute;
                    transform: skew(-8deg);
                    z-index: 0;
                    left: 90%;
                    opacity: 1;
                }
                .para{
                    color: $white;
                    &.date{
                        font-family: $arima;
                        font-weight: 700;
                    }
                }
            }
            .pic{
                display: block;
                width: 100%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    &.calendrier{
        padding: 64px 0;
        background-color: $gris;
        .next-match{
            position: relative;
            max-height: 500px;
            overflow: hidden;
            @media (max-width: 767px){
                margin-bottom: 32px;
            }
            .img-fluid{
                height: 100%;
                object-fit: cover;
            }
            .details{
                position: absolute;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                padding: 16px;
                top: 0;
                color: $white;
                .team{
                    a{
                        color: $white;
                        text-decoration: none;
                        .team-logo{
                            width: 100px;
                        }
                    }
                    .vs{
                        margin: 0 24px;
                    }
                }
            }
        }
        .tab-match{
            max-height: 500px;
            overflow-y: scroll;
            .matches{
                background-color: $white;
                padding: 10px;
                align-items: center;
                border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
                cursor: pointer;
                transition:border 1s cubic-bezier(0.165, 0.84, 0.44, 1);
                &:hover{
                    border-bottom: 2px solid $secondary;
                }
                .team{
                    a{
                        display: flex;
                        align-items: center;
                        @media (max-width: 767px){
                            font-size: 14px;
                        }
                        .team-logo{
                            max-width: 80px;
                            @media (max-width: 767px){
                                width: 60px;
                            }
                        }
                        span{
                            width: 65px;
                            @media (max-width: 380px){
                                width: 50px;
                            }
                        }
                    }
                }
                .details{
                    width: 100%;
                    span{
                        display: block;
                        text-align: center;
                        &.date,&.stade{
                            color: #666;
                            font-size: 12px;
                            text-transform: uppercase;
                        }
                        &.vs{
                            color: $white;
                            background-color: $secondary;
                            padding:5px 10px;
                            font-size: 16px;
                            width: fit-content;
                            margin: 10px auto;
                            border-radius: 10px;
                        }
                    }
                }
            }
        }
    }
    &.gallery{
        background-color: $primary;
        .ttr{
            color: $white;
        }
        .gallery-container{
            display: grid;
            grid-gap: 6px;
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            grid-auto-rows: 200px;
            grid-auto-flow: dense;
            margin-top: 54px;
            @media (max-width: 767px){
                grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
                grid-auto-rows: 100px;
            }
            > a {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 2em;
                position: relative;
                .content-overlay {
                    background: rgba(0,0,0,0.7);
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    opacity: 0;
                    -webkit-transition: all 0.4s ease-in-out 0s;
                    -moz-transition: all 0.4s ease-in-out 0s;
                    transition: all 0.4s ease-in-out 0s;
                }  
                &:hover .content-overlay{
                    opacity: 1;
                }
                .content-details {
                    position: absolute;
                    text-align: center;
                    padding-left: 1em;
                    padding-right: 1em;
                    width: 100%;
                    top: 80%;
                    left: 50%;
                    opacity: 0;
                    -webkit-transform: translate(-50%, -50%);
                    -moz-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    -webkit-transition: all 0.3s ease-in-out 0s;
                    -moz-transition: all 0.3s ease-in-out 0s;
                    transition: all 0.3s ease-in-out 0s;
                }
                &:hover .content-details{
                    top: 50%;
                    left: 50%;
                    opacity: 1;
                }
            } 
            > a > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                box-shadow: 0 2px 16px rgba(0,0,0,.2);;
              }
            .horizontal {
                grid-column: span 2;
                &.large{
                    grid-column: span 3;
                    @media (max-width: 1200px){
                        grid-column: span 4;
                    }
                }
            }
            .vertical {
                grid-row: span 2;
            }
            .big {
                grid-column: span 3;
                grid-row: span 2;
                @media (max-width: 1200px){
                    grid-column: span 4;
                }
            }
        }
    }
    &.players{
        .nav-tabs{
            border:none;
            margin-bottom: 32px;
            .nav-item{
                .nav-link{
                    padding: 0;
                    margin-right: 1rem;
                    border: none;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: #232323;
                    &.active{
                        border-bottom:5px solid $secondary
                    }
                }
            }
        }
        .tab-content{
            .sttr{
                letter-spacing: normal;
                color: #666;
                font-style: italic;
                font-weight: 300;
                border-bottom: 1px solid #dedede;
                padding-bottom: 24px;
            }
            .col-md-3{
                margin-bottom: 24px;
                .players-card{
                    width: 100%;
                    text-decoration: none;
                    display: flex;
                    flex-direction: column;
                    border: 1px solid #d3d3d3;
                    border-radius: 5px;
                    overflow: hidden;
                    top: 0px;
                    position: relative;
                    &:hover {
                        transition: all 0.2s ease-out;
                        box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
                        top: -4px;
                        border: 1px solid #cccccc;
                        background-color: white;
                    }
                    img{
                        width: 100%;
                    }
                    .players-card-details{
                        display: flex;
                        align-items: center;
                        .numero{
                            position: relative;
                            background-color: $primary;
                            color:$white;
                            padding: 13px;
                            font-size: 20px;
                            font-weight: 700;
                            &:after {
                                content: "";
                                top: 0;
                                bottom: 0;
                                right: -16%;
                                position: absolute;
                                transform: skew(-9deg);
                                z-index: 0;
                                left: 89%;
                                background-color: $primary;
                            }
                        }
                        .name{
                            width: 100%;
                            padding: 18px 0 17px 17px;
                            background-color: $secondary;
                            text-transform: uppercase;
                            color: $white;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    &.next-match{
        .d-flex{
            @media (max-width: 767px){
                flex-direction: column;
            }
        }
        .team{
            padding: 24px 36px;
            display: flex;
            align-items: center;
            position: relative;
            @media (max-width: 767px){
                padding: 16px 24px;
            }
            &.home{
                background-color: $secondary;
                &:before {
                    content: "";
                    top: 0;
                    bottom: 0;
                    right: -5%;
                    position: absolute;
                    transform: skew(-8deg);
                    z-index: 0;
                    left: 95%;
                    opacity: 1;
                    background-color: $secondary;
                }
                @media (max-width: 767px){
                    align-self: flex-start;
                }
            }
            &.away{
                background-color: $primary;
                &:before {
                    content: "";
                    top: 0;
                    bottom: 0;
                    left: -5%;
                    position: absolute;
                    transform: skew(-8deg);
                    z-index: 0;
                    right: 95%;
                    opacity: 1;
                    background-color: $primary;
                }
                @media (max-width: 767px){
                    align-self: flex-end;
                }
            }
            .team-logo{
                max-width: 200px;
                @media (max-width: 767px){
                    max-width: 170px;
                }
                @media (max-width: 380px){
                    max-width: 105px;
                }
            }
            .text{
                .classement{
                    color: $white;
                    padding-bottom: 0;
                    width: max-content;
                }
            }
        }
        .vs{
            width: 100%;
            @media (max-width: 767px){
                margin: 32px 0;
            }
            .vs{
                margin:1rem 0;
                span{
                    padding: 8px 5px;
                    color: $white;
                    font-size:24px;
                    font-style: italic;
                    position: relative;
                    &.v{
                        background-color: $secondary;
                        &:before {
                            content: "";
                            top: 0;
                            bottom: 0;
                            left: -20%;
                            position: absolute;
                            transform: skew(-9deg);
                            z-index: 0;
                            right: 80%;
                            background-color: $secondary;
                        }
                        &:after {
                            content: "";
                            top: 0;
                            bottom: 0;
                            right: -20%;
                            position: absolute;
                            transform: skew(-9deg);
                            z-index: 0;
                            left: 80%;
                            background-color: $secondary;
                        }
                    }
                    &.s{
                        background-color: $primary;
                        &:before {
                            content: "";
                            top: 0;
                            bottom: 0;
                            left: -20%;
                            position: absolute;
                            transform: skew(-9deg);
                            z-index: 0;
                            right: 80%;
                            background-color: $primary;
                        }
                        &:after {
                            content: "";
                            top: 0;
                            bottom: 0;
                            right: -20%;
                            position: absolute;
                            transform: skew(-9deg);
                            z-index: 0;
                            left: 80%;
                            background-color: $primary;
                        }
                    }
                }
            }
            .date{
                margin-bottom: 0;
            }
        }
    }
    &.classement{
        overflow-x: auto;
        .table{
            thead{
                tr{
                    th{
                        background-color: $primary;
                        border:none;
                        color: $white;
                        text-transform: uppercase;
                        font-weight: 400;
                        &.rg{
                            width: 44px;
                        }
                        &.logo{
                            width: 100%;
                            @media (max-width: 380px){
                                display: none;
                            }
                        }
                        i{
                            display: initial;
                            margin-left: 5px;
                        }
                        .eqp{
                            display: block;
                            width: fit-content;
                            padding: 0.75rem;
                        }
                    }
                }
            }
            tbody{
                tr{
                    td{
                        vertical-align: middle;
                        padding: 0;
                        text-align: center;
                        &:first-child{
                            padding-left: 0;
                        }
                        &.pic{
                            text-align: left;
                            @media (max-width: 380px){
                                display: none;
                            }
                        }
                        .rg,.eqp{
                            display: block;
                            padding:0.75rem;
                            color:$white;
                            text-align: left;
                        }
                        .rg{
                            background-color: $primary;
                            text-align: center;
                        }
                        .logo{
                            max-width: 70px;
                            margin-left: 24px;
                        }
                    }
                }
            }
            .rg{
                background-color: $secondary;
            }
            .eqp{
                background-color: $secondary;
                position: relative;
                &:after {
                    content: "";
                    top: 0;
                    bottom: 0;
                    right: -4%;
                    position: absolute;
                    transform: skew(-9deg);
                    z-index: 0;
                    left: 96%;
                    background-color: $secondary;
                }
            }
        }
    }
    &.partner{
        .partner-slide{
            .slick-arrow{
                z-index: 1;
                background-color: $primary;
                padding: 6px 10px;
                height: auto;
                width: auto;
                z-index: 1;
                &::before{
                    content: "";
                }
                &.slick-next{
                    right:-50px
                }
                &.slick-prev{
                    left:-50px
                }
                .fas{
                    color: $white;
                    font-size: 15px;
                }
            }
            a{
                height: 140px;
                margin: 0 8px;
                border: 1px solid #dedede;
                border-radius: 10px;
                overflow: hidden;
                transition:all .4s ease-in-out;
                &:hover{
                    transform: scale(0.9);
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    &.banner{
        min-height:40vh;
        max-height:40vh;
        overflow: hidden;
        margin-top: 73px;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        &::after{
            content: "";
            top: 0;
            bottom: 0;
            left: -16%;
            position: absolute;
            transform: skew(-9deg);
            z-index: 0;
            right: 40%;
            background-color: rgba($color: #fff, $alpha: 0.7);
            z-index: 1;
        }
        img{
            width:100%;
            height:100%;
            object-fit: cover;
            position: absolute;
        }
        .container{
            position: relative;
            z-index: 2;
            .text{
                .fil-ariane{
                    list-style: none;
                    padding: 0;
                    li{
                        font-weight: 500;
                        display: inline-block;
                        text-transform: uppercase;
                        &.active{
                            color: $secondary;
                            border-bottom: 2px solid $secondary;
                        }
                    }
                }
            }
        }
    }
    &.table-players{
        background-color: #f7f7f7;
        overflow-x: auto;
        .table{
            &.players{
                margin-bottom: 2rem;
                @media (max-width: 767px){
                    min-width: 600px;
                }
                thead{
                    th{
                        padding-left: 0;
                        border: none;
                        color:#666;
                        font-size:14px;
                        font-weight: 500;
                        &.title{
                            color:#232323;
                            font-size:30px;
                            font-weight: 600;
                        }
                    }
                }
                tbody{
                    tr{
                        background-color: $white;
                        cursor: pointer;
                        &:hover{
                            background-color: $secondary;
                            box-shadow: 5px 5px 5px rgba(0,0,0,.2);
                            td{
                                color: $white;
                            }
                        }
                        td{
                            padding: 0;
                            vertical-align: middle;
                            border-top: none;
                            border-bottom: 1px solid #dee2e6;
                            .pts{
                                font-weight: 700;
                                background-color: rgba($color: #000000, $alpha: 0.1);
                                padding: 4px 10px;
                                border-radius: 4px;
                            }
                            img{
                                max-width: 80px;
                            }
                            .numero{
                                background-color: $primary;
                                font-size: 13px;
                                padding: 4px 6px 4px 10px;
                                border-radius: 5px;
                                color:$white;
                                margin:0 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.footer{
    margin-top: 48px;
    .col-md-5{
        z-index: 1;
        .contact{
            background-color: #232323;
            padding: 48px 0px 48px 24px;
            @media (max-width: 767px){
                flex-direction: column;
            }
            &:before{
                content: '';
                background-color: #232323;
                top: 0;
                bottom: 0;
                right: -7%;
                position: absolute;
                transform: skew(-10deg);
                z-index: 0;
                left: 90%;
                @media (max-width: 767px){
                    display: none;
                }
            }
            .pic{
                margin-right: 16px;
                img{
                    max-width: 200px;
                }
            }
            .text{
                position: relative;
                z-index: 1;
                .sttr{
                    color: $white;
                }
                .para{
                    color:#999999;
                    i{
                        margin-right: 12px;
                    }
                }
            }
        }
    }
    .col-md-7{
        iframe{
            width: 100%;
            height: 100%;
            min-height: 290px;
        }
    }
}