@import "variables";
// @import "node_modules/bootstrap/scss/bootstrap";
@import "./plugins/bootstrap/bootstrap.scss";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/solid";

@import "./plugins/slick/slick";
@import "./plugins/slick/slick-theme.scss";
body{
    font-family: $adelle;
}
.ttr{
    font-family: $arima;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 48px;
}
.sttr{
    font-family: $arima;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 22px;
    margin-bottom: 32px;
    letter-spacing: 0.15rem;
    @media (max-width: 380px){
        font-size: 18px;
    }
}
.para{
    font-family: $adelle;
    font-weight: 300;
}
.no-uppercase{
    text-transform: none;
}
.langue{
    position: absolute;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    z-index: 2;
    right:53px;
    top:47px;
    span{
        margin:0 4px;
    }
    .para{
        margin-bottom: 0;
        font-weight: 500;
        &.active{
            color: $primary;
        }
    }
}
.desktop-hide{
    display: none;
    @media (max-width: 767px){
        display: block;
    }
}
.mobile-hide{
    @media (max-width: 767px){
        display: none;
    }
}
.btn{
    border-radius: 10px;
    border-bottom:1px solid rgba($color: #000000, $alpha: 0.1);
    box-shadow: 1px 1px 5px rgba($color: #000000, $alpha: 0.5);
    &.orange{
        color: $white;
        background-color: $secondary;
        width: fit-content;
        margin: 0 auto;
    }
}